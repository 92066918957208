import { Component } from '@angular/core';

@Component({
    selector: 'app-tutorial',
    templateUrl: './tutorial.component.html',
    styleUrls: ['./tutorial.component.css'],
    standalone: false
})
export class TutorialComponent {

}
