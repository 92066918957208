import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-imprint',
    templateUrl: './imprint.component.html',
    styleUrls: ['./imprint.component.css'],
    standalone: false
})
export class ImprintComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
